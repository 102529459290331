<template>
  <div :class="`support ${themeName}`">
    <!-- <Navigation></Navigation> -->
    <section>
      <Success></Success>
      <Footer></Footer>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import Navigation from '@common/Navigation'
import Footer from '@desktop/adminOrViewer/detection/Footer'
import Success from '@desktop/adminOrViewer/support/Success'
import RotuerMixin from '@/mixin/router.mixin'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [RotuerMixin, Theme],
  components: { Footer, Success },
}
</script>

<style lang="scss" scoped>
.support {
  display: flex;
  height: 100%;
  overflow: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  height: 0;
  min-height: 100%;
  overflow: auto;
}

.support-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 57px;
  margin-top: 100px;
  align-self: center;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

form {
  width: 752px;
  align-self: center;
  font-weight: 600;
  font-size: 18px;

  label {
    min-width: 236px;
    height: 44px;
    line-height: 44px;
  }

  .asterisk {
    color: var(--f-primary-50);
  }
}

.form-row {
  display: flex;
}
</style>
